import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ContactPage() {
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const validationSchema = yup.object().shape(
    {
      name: yup.string().required('Required'),
      email: yup.string().required('Required').email("Invalid Email"),
      project_type: yup.string().required('Required'),
      mobile: yup.number().required('Required'),
      message: yup.string().required('Required'),
    }
  );

  const { register, handleSubmit, reset, clearErrors, setValue, setError, getValues, watch, control, formState: { errors, dirtyFields } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all"
  });

  const [formData, setFormData] = useState({
    name: 'jeevan',
    email: 'deepakins1@gmail.com',
    message: 'hello g1'
  });

  const formSubmitHandler = (val) => {
    console.log('val', val)
    console.log('val', process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, val, process.env.REACT_APP_EMAIL_JS_USER_ID)

    emailjs.send(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, val, 
      process.env.REACT_APP_EMAIL_JS_USER_ID)
      .then((response) => {
        if(response.status === 200){
          toast.success("Message sent successfully");
          reset();
        }else{
          toast.error("Error in sending message, please try again later.");
        }
      })
      .catch((error) => {
        // console.error('Email could not be sent:', error);
        toast.error("Error in sending message, please try again later.");
        // Add error handling logic here
      });
  };
  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row" onSubmit={handleSubmit(formSubmitHandler)}>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input type="text" className="cs-form_field" {...register('name')} />
                {errors?.name && <span className='cs-red_color'>{errors?.name?.message}</span>}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="email" className="cs-form_field" {...register('email')} />
                {errors?.email && <span className='cs-red_color'>{errors?.email?.message}</span>}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input type="text" className="cs-form_field" {...register('project_type')} />
                {errors?.project_type && <span className='cs-red_color'>{errors?.project_type?.message}</span>}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="number" defaultValue={0} className="cs-form_field" {...register('mobile')} />
                {errors?.mobile && <span className='cs-red_color'>{errors?.mobile?.message}</span>}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  {...register('message')}
                ></textarea>
                {errors?.message && <span className='cs-red_color'>{errors?.message?.message}</span>}
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" type="submit">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
          allowFullScreen
          title="Google Map"
        /> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109552.20487213056!2d75.77427137817908!3d30.90046639297969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a837462345a7d%3A0x681102348ec60610!2sLudhiana%2C%20Punjab!5e0!3m2!1sen!2sin!4v1716631975394!5m2!1sen!2sin"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
      <ToastContainer />
    </>
  );
}
